.Fooer-container{
    position: relative;
    padding: 0 2rem;
}
.Footer-container>hr{
    border: 1px solid var(--lightgray);
    margin-inline: 2rem;
}
.footer{
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    margin-bottom: 2rem;
}
.social-links{
    display: flex;
    gap: 4rem;
    justify-content: center;

}
.social-links>img{
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    padding: 0.5rem;
}
.logo-f{
    display: flex;
    justify-content: center;
    padding-bottom: 2rem;
}
.logo-f>img{
    width: 10rem;
}
.blur-footer{
    width: 26rem;
    height: 12rem;
    bottom: 1rem;
    /* right: 15%; */
    left: 12rem;

}