.Testimonials{
    display: flex;
    gap: 1rem;
    padding: 0 2rem;
    margin-bottom: 1rem;
}
.left-t{
    flex:1;
    display: flex;
    gap: 2rem;
    flex-direction: column;
    text-transform: uppercase;
    color:white;
    /* background: black; */
}

.left-t>:nth-child(1){
    color:var(--orange);
    font-weight: bold;
    font-size: clamp(20px, 8vw, 24px);
}
.left-t>:nth-child(2), .left-t>:nth-child(3){
    font-weight: bold;
    font-size: clamp(20px, 8vw, 24px);
}
.left-t>:nth-child(4){
    text-transform: capitalize;
    text-align: justify;
    line-height: 2.5rem;
    letter-spacing: 2px;
}   
.right-t{
    flex:1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
}
.right-t>img{
    width: 17rem;
    height: 20rem;
    object-fit: cover;
    position: absolute;
    right: 8rem;
    top: 2rem;
}
.right-t>:nth-child(1){
    position: absolute;
    width: 17rem;
    height: 20rem;
    background-color: transparent;
    right: 8.8rem;
    top: 1.2rem;
    border: 2px solid var(--orange);
}
.right-t>:nth-child(2){
    position: absolute;
    width: 17rem;
    height: 20rem;
    background-color: var(--orange);
    right: 7.2rem;
    top: 2.8rem;
    border: 2px solid var(--orange);
}
.arrows{
    position: absolute;
    bottom: 1.8rem;
    left: 2rem;
    display: flex;
    gap: 1rem;
}
.arrows img{
    height: 2rem;
    width: 2rem;
    cursor: pointer;
}
.arrows img:hover{

}
@media screen and (max-width:768px){
    .Testimonials{
        flex-direction: column;
    }
    .right-t{
        align-items: center;
        gap: 2rem;
    }
    .right-t>:nth-child(1),    .right-t>:nth-child(2) {
        position: relative;
        display: none;
    }
    .right-t>img{
        position: relative;
        display: flex;
        top: 0;
        right: 0;
        align-self: center;
        /* margin: 0 auto; */
    }
    .arrows{
        position: relative;
        display: flex;
        justify-content: center;
        bottom: 0;
        left: 0; 
        gap: 2rem;
        align-self: center;
    }
    .left-t>:nth-child(5){
        text-align: center;
    }
    .left-t>:nth-child(1), .left-t>:nth-child(2), .left-t>:nth-child(3){
        text-align: center;
    }
}