.header{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.logo{
    width: 10rem;
    height: 3rem;
}
.hamburger{
    width: 100%;
    width: 2.5rem;
    height: 1rem;
    background-color: var(--appColor);
    padding: 0.4rem;
    border-radius: 0.4rem;
}
.header-menu{
    display: flex;
    list-style: none;
    gap: 2rem;
    color:white; 

}
.header-menu>li:hover{
    cursor: pointer;
    color: var(--orange);
}
@media screen and (max-width:920px){
    .header>:nth-child(2){
        position: fixed;
        right: 1rem;
        z-index: 3;
    }
    .header-menu{
        flex-direction: column;
        background-color: var(--appColor);
        padding: 2rem;
        border-radius: 1rem;
    }

}